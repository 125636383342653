import React from "react";
import Layout from "../components/layout";
import Helmet from "react-helmet";
import { Link } from "gatsby";

export default () => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>MUDr. Rastislav Šušol - Urologická ambulance</title>
    </Helmet>

    <div id="page" class="sixteen columns">
      <div class="inner">
        <div id="page-header">
          <h1 id="page-title" className="title">
            Stránka se nenašla
          </h1>
        </div>

        <div id="content" className="content clearfix">
          <p>
            Obsah neexistuje, <Link to="/">na úvod...</Link>
          </p>
        </div>
      </div>
    </div>
  </Layout>
);
